alertify.set({
  buttonReverse: true,
  labels : {
    ok : 'Да',
    cancel : 'Нет'
  }
});

$(function() {
  $(':checkbox, :radio').not('.hidden').forms();
  $('select').sSelect();

  $('body').on('yiiListViewUpdated', function(){
    $(':checkbox, :radio').not('.hidden').forms();
    $('select').sSelect();

    var $catalog = $('.catalog');
    var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;
    $(window).scrollTo( $catalog, {
      duration: scrollSpeed
    });
  });

  $('input[type="tel"], .phone-input').each(function() {
    var $_this = $(this), val;
    $_this.mask('+7 (999) 999-99-99', {autoclear: false});
  });

  $('.fancybox').fancybox();

  $('body').on('click', '.spinner-up, .spinner-down', function() {
    // Клик по + - на спиннерах
    var self = $(this),
      spinner = $(this).closest('.spinner'),
      input = spinner.find('.inp'),
      step = input.data('step') ? input.data('step') : 1,
      zero = input.data('zero') ? input.data('zero') : false;
    value = parseInt( input.val(), 10 );
    if ( self.hasClass('spinner-up') ) {
      value += step;
    } else {
      if ( zero ) {
        if ( value >= step ) {
          value -= step;
        }
      } else {
        if ( value > step ) {
          value -= step;
        }
      }
    }
    input.val(value).trigger('change');
  });

  $('body').on('keydown', '.spinner .inp', function(e) {
    var inp = $(this);
    // Разрешаем: backspace, delete, tab и escape
    if ( e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 ||
      // Разрешаем: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Разрешаем: home, end, влево, вправо
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    } else {
      // Убеждаемся, что это цифра, и останавливаем событие keypress
      if ( (e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105 ) ) {
        e.preventDefault();
      }
    }
  });

  $('body').on('keyup paste', '.spinner .inp', function(e) {
    var inp = $(this);
    if ( inp.val() == 0 ) {
      inp.val(1);
    }
  });

  $('.popup .close').click(function(e) {
    e.preventDefault();
    var target = $(this).closest('.popup').attr('id');
    $.overlayLoader(false, target);
  });

  // перемещалка активного раздела меню
  (function() {
    if ($('#main').hasClass('js-catalog-page')) {
      var $nav = $('#left > .left-menu > ul');
      var $sub = $nav.find('> .active');

      if ($sub.length) $nav.prepend($sub.detach());
    }
  }());

  // var leftOff = $('<div id="left-off-aside"></div>'),
  //     goTopBtn = $('<a href="" class="go-top">Наверх</a>');
  // leftOff.append(goTopBtn);
  // $('body').append(leftOff);

  // var setOffsideWidth = function(){
  //   var freeSpace = ( $(window).width() - $('.wrapper').width() ) / 2;
  //   $('#left-off-aside').css('width', freeSpace);
  // };

  // setOffsideWidth();

  // $(window).scroll(function(){
  //   var scrollTop = $(window).scrollTop();
  //   if ( scrollTop > 300 ) {
  //     $('#left-off-aside').addClass('visible');
  //   } else {
  //     $('#left-off-aside').removeClass('visible');
  //   }
  // });

  // $(window).resize(function(){
  //   setOffsideWidth();
  // });

  // $('#left-off-aside').click(function(){
  //   $('.go-top').trigger('click');
  // });

  // $('.go-top').click(function(e){
  //   e.preventDefault();
  //   e.stopPropagation();
  //   $('body').scrollTo($('header'), 300);
  // });
});